import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import './Education.css';
import { educationData } from '../../data/educationData';
import retOrder from '../../assets/png/retailer_orders.PNG';
import genLogin from '../../assets/png/general_login.PNG';
import retStock from '../../assets/png/retailer_stock.PNG';

function Education() {
    const { theme } = useContext(ThemeContext);

    return (
        <div className="education" id="screenshots" style={{ backgroundColor: theme.secondary }}>
            <div className="education-body">
                <div className="education-description">
                    <h1 style={{ color: theme.primary }}>Mobile Application Screenshots</h1>
                    <div className="image-container">
                        <img src={retOrder} alt="Retailer Order" className="education--img" />
                        <img src={genLogin} alt="General Login" className="education--img" />
                        <img src={retStock} alt="Retailer Stock" className="education--img" />
                    </div>
                    <h2 style={{ color: theme.primary }}>Mobile Application - Coming Soon!!!</h2>
                </div>
            </div>
        </div>
    );
}

export default Education;
